import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Wholesale from "../views/Wholesale.vue";
import Giftbox from "../views/Giftbox.vue";
import Familypack from "../views/Familypack.vue";
import Aboutus from "../views/Aboutus.vue";
import Contactus from "../views/Contactus.vue";
import Diwali from "../views/Diwali.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/diwali",
    name: "Diwali",
    component: Diwali,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/wholesale",
    name: "Wholesale",
    component: Wholesale,
  },
  {
    path: "/giftbox",
    name: "Giftbox",
    component: Giftbox,
  },
  {
    path: "/familypack",
    name: "Familypack",
    component: Familypack,
  },
  {
    path: "/aboutus",
    name: "Aboutus",
    component: Aboutus,
  },
  {
    path: "/contactus",
    name: "Contactus",
    component: Contactus,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = "srisaipattasukadal@gmail.com";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
