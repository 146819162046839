import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f33503",
        secondary: "#F8FF08",
        accent: "#d30606",
        error: "#b71c1c",
      },
    },
  },
});
